/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Import.vue?vue&type=template&id=3b27b9ff&scoped=true&lang=pug&"
import script from "./Import.vue?vue&type=script&lang=ts&"
export * from "./Import.vue?vue&type=script&lang=ts&"
import style0 from "./Import.vue?vue&type=style&index=0&id=3b27b9ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b27b9ff",
  null
  
)

export default component.exports