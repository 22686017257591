import _slicedToArray from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.slice.js";
import { defineComponent } from 'vue';
import { ImportTyp, ImportSteps } from '@rose/types';
import { toPairs, find } from 'lodash';
export default defineComponent({
  props: {
    importType: {
      type: String,
      required: true,
      "default": ImportTyp.FULL
    },
    importState: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showDetails: false,
      importSteps: ImportSteps
    };
  },
  computed: {
    scripts: function scripts() {
      return toPairs(this.importState.scripts);
    }
  },
  methods: {
    isLastEntryWithDate: function isLastEntryWithDate(entry, index) {
      // Check if the current entry has a date
      if (!entry[1]) return false; // Look for any subsequent entries with dates

      var subsequentEntryWithDate = find(this.scripts.slice(index + 1), function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return value;
      }); // If no subsequent entries with dates are found, this is the last entry with a date

      return !subsequentEntryWithDate;
    }
  }
});